<template>
	<b-input-group>
		<b-form-input type="text" :id="id" v-model="filter" @keyup.enter="onFilterUsers" :placeholder="this.FormMSG(1, 'Type to Search')" />
		<b-input-group-text class="btn-search">
			<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
			<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
		</b-input-group-text>
		<b-tooltip :target="id" placement="top" triggers="focus">
			{{ this.FormMSG(2, "Type 'Enter' to launch search") }}
		</b-tooltip>
	</b-input-group>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { Search, X } from 'lucide-vue';

export default {
	name: 'filterInputSearch',
	mixins: [languageMessages, globalMixin],
	props: {
		id: {
			type: String,
			required: false,
			default: 'filterInput'
		},
		initValue: {
			type: String,
			default: '',
			required: false
		}
	},
	components: {
		Search,
		X
	},
	data: () => {
		return {
			filter: ''
		};
	},
	watch: {
		initValue: {
			handler(newVal) {
				this.filter = newVal;
			},
			immediate: true
		},
		filter: {
			handler(newVal) {
				if (newVal.length === 0) {
					this.$emit('handle-init-filter');
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {},
	methods: {
		onFilterUsers() {
			this.$emit('filtered-users', this.filter);
		}
	},
	async created() {}
};
</script>
