var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-input-group",
    [
      _c("b-form-input", {
        attrs: {
          type: "text",
          id: _vm.id,
          placeholder: this.FormMSG(1, "Type to Search"),
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onFilterUsers.apply(null, arguments)
          },
        },
        model: {
          value: _vm.filter,
          callback: function ($$v) {
            _vm.filter = $$v
          },
          expression: "filter",
        },
      }),
      _c(
        "b-input-group-text",
        { staticClass: "btn-search" },
        [
          _vm.filter.length === 0
            ? _c("Search", {
                staticClass: "icon",
                attrs: { color: "#FFFFFF", size: 16, "stroke-width": 2.5 },
              })
            : _c("X", {
                staticClass: "icon",
                attrs: { color: "#FFFFFF", size: 16, "stroke-width": 2.5 },
                on: {
                  click: function ($event) {
                    _vm.filter = ""
                  },
                },
              }),
        ],
        1
      ),
      _c(
        "b-tooltip",
        { attrs: { target: _vm.id, placement: "top", triggers: "focus" } },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(this.FormMSG(2, "Type 'Enter' to launch search")) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }